// 列表文件上传模板
<template>
    <div :class="showBlock ? 'itemFiles':'itemFile'">
        <div class="filesBox">
            <div :class="['files', fileList.length === 0 ? 'emptyBg' : '']"
                 :style="{height: fileBoxHeight}"
                 v-loading="loading">

                <!-- <el-row class="emptyText"
                        v-if="fileList.length === 0">暂无文件</el-row> -->

                <div class="fileList">

                    <div class="fileItem"
                         v-for="(item,idx) in fileList"
                         :key="idx">
                        <div class="fileRow fileName fileNameCol">
                            <el-tooltip class="item"
                                        effect="dark"
                                        :content="item.name || ''"
                                        placement="top-start">
                                <span class="cursor"
                                      @click="seeView(item)">{{item.name || ''}}</span>
                            </el-tooltip>
                        </div>
                        <div class="fileRow personNameCol">
                            <el-tooltip class="item"
                                        effect="dark"
                                        :content="item.addUserName || ''"
                                        placement="top-start">
                                <span class="ellipsis">{{item.addUserName || ''}}</span>
                            </el-tooltip>
                        </div>
                        <div class="fileRow dateTime timeCol">
                            <el-tooltip class="item"
                                        effect="dark"
                                        :content="item.createTime || ''"
                                        placement="top-start">
                                <span class="ellipsis">{{item.createTime || ''}}</span>
                            </el-tooltip>
                        </div>
                        <div class="controlCol">
                            <div class="controlBtn">
                                <span class="iconDown"
                                      v-if="showDownloadBtn"
                                      @click="downLoadFile(item)"></span>
                                <span class="iconView"
                                      v-if="commonJs.filterIsImg(item.name) || commonJs.filterIsPdf(item.name)"
                                      @click="seeView(item)"></span>
                                <template v-if="showDelBtn">
                                    <el-popconfirm v-if="showUpBtn"
                                                   confirm-button-text="删除"
                                                   cancel-button-text="取消"
                                                   icon="el-icon-info"
                                                   icon-color="red"
                                                   @confirm="deleteFile(item)"
                                                   style="margin: 0"
                                                   title="是否删除该文件，删除后无法恢复此文件！">
                                        <span slot="reference"
                                              class="iconDel"></span>
                                    </el-popconfirm>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 上传单独做一行 -->
                <div class="uploadBox">
                    <el-upload class="upload-demo"
                               name="file"
                               v-if="showUpBtn"
                               :headers="configHeader"
                               :action="action"
                               :on-success="uploadSuccess"
                               :before-upload="beforeUpload"
                               :on-error="uploadError"
                               multiple
                               :data="uploadData"
                               :show-file-list="false">
                        <div class="uploadBtn">
                            <i class="fileUpload"></i>
                            <span>请点击上传附件</span>
                        </div>
                    </el-upload>
                </div>

            </div>

            <div class="title ofh"
                 v-if="form.title || downTemplateUrl || isNeedPopTemDownload || uploadContractBnt || contractRecord">
                <b class="title_">{{form.title || ''}}</b> <span class="title_"
                      v-if="showNumber">（{{fileList.length}}）</span>
                <a type="primary"
                   v-if="downTemplateUrl"
                   class="el-button el-button--primary el-button--mini flr downBtn"
                   :href="downTemplateUrl"
                   size="mini"
                   target="_blank">下载模板<i class="el-icon--right el-icon-download"></i></a>
                <!-- 新增下载模板模块 -->
                <el-button type="success"
                           size="mini"
                           class="flr ml15"
                           v-if="isNeedPopTemDownload"
                           @click="openpopDownLoadTemFile()">下载模板</el-button>
                <div class="controlBtn downAndUpload flr">
                    <el-button v-if="uploadContractBnt"
                               @click="uploadContractPop()"
                               type="primary"
                               size="mini">上传合同<i class="el-icon-upload el-icon--right"></i></el-button>
                    <el-button v-if="contractRecord"
                               @click="contractRecordPop()"
                               type="primary"
                               size="mini">变更记录<i class="el-icon-time el-icon--right"></i></el-button>
                </div>
            </div>
        </div>

        <!-- 下载模板 -->
        <pop-download-tem-file v-if="popDownLoadTemFileVisible"
                               :modelName="modelName"
                               :resourceId="uploadData.resourceId"
                               :dataRecommendTep="dataRecommendTep"
                               @close="closepopDownLoadTemFile"></pop-download-tem-file>

        <!-- 图片预览 -->
        <elImageViewer v-if="showViewer"
                       :onClose="showViewerClose"
                       :url-list="[guidePic]"></elImageViewer>

        <!-- pdf预览 -->
        <viewPdf v-if="showPdfView"
                 :pdfUrl="pdfUrl"
                 :title="filename"
                 @close="closeDialog"></viewPdf>
    </div>

</template>

<script>
import commonAPI from "@/api/commonAPI.js";
import popDownloadTemFile from "@/components/popDownloadTemFile.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer"; //  图片预览组件
import viewPdf from "@/components/viewPdf/viewPdf"; //  PDF预览组件
export default {
    name: "comFileUploadList",
    props: {
        styles: {
            type: String,
            default: "height:auto;max-height:300px",
        },
        // 排序方式
        sort: {
            type: String,
            default: null,
        },
        // 模块名称 因为项目和实施比较特殊
        modelName: {
            type: String,
            default: null,
        },
        //上传地址
        action: {
            type: String,
            default: window.configPath.baseUrl + "/filem/upload",
        },
        form: {
            type: Object,
            default() {
                return {
                    title: "",
                };
            },
        },
        showUpBtn: {
            //显示上传按钮
            type: Boolean,
            default: false,
        },
        showDownloadBtn: {
            //显示下载按钮
            type: Boolean,
            default: true,
        },
        showDelBtn: {
            //显示删除按钮
            type: Boolean,
            default: true,
        },
        showBlock: {
            //是否显示边框border
            type: Boolean,
            default: false,
        },
        showNumber: {
            //是否显示文件数量
            type: Boolean,
            default: false,
        },
        justShowFistOne: {
            //只显示最新一条数据
            type: Boolean,
            default: false,
        },
        //上传数据
        uploadData: {
            type: Object,
            default() {
                return { resourceId: 0, resourceType: "" };
            },
        },
        //下载模板地址
        downTemplateUrl: {
            type: String,
            default: "",
        },
        //文件模板下载容器高度
        fileBoxHeight: {
            type: String,
            default: "190px",
        },
        //是否需要拉取附件列表
        neddLoadFile: {
            type: Boolean,
            default: false,
        },
        // 是否需要下载模板的弹窗
        isNeedPopTemDownload: {
            type: Boolean,
            default: false,
        },

        //是否需要强制刷新
        refreshList: {},

        /* ***********************特殊部分  针对业务模块的参数********************** */
        //合同模块  上传合同
        uploadContractBnt: {
            type: Boolean,
            default: false,
        },

        //合同模块  合同附件变更记录
        contractRecord: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        //加载附件
        neddLoadFile: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                setTimeout(() => {
                    newVal && this.getFilteList();
                }, 100);
            },
        },

        //是否需要强制刷新
        refreshList: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal && this.getFilteList();
            },
        },
    },
    components: {
        "pop-download-tem-file": popDownloadTemFile,
        elImageViewer: ElImageViewer,
        viewPdf: viewPdf,
    },

    data() {
        return {
            token: this.commonJs.getCookie("ERPToken"),
            loading: false,
            guidePic: null, // 预览图片的地址
            pdfUrl: null, // 预览pdf的地址
            showViewer: false, //大图查看
            showPdfView: false, //pdf查看
            filename: "", //当前查看的文件名称
            fileBoxHeigh: "300px",
            fileList: [], //文件上传
            popDownLoadTemFileVisible: false, // 下载模板弹窗显示
            dataRecommendTep: {}, // 下载模板所需data
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 下载模板 打开弹框
        openpopDownLoadTemFile() {
            let item = {
                resourceType: this.uploadData.resourceType,
            };
            this.dataRecommendTep = item;
            this.popDownLoadTemFileVisible = true;
        },
        // 下载模板 关闭弹框
        closepopDownLoadTemFile() {
            this.popDownLoadTemFileVisible = false;
        },
        //获取附件列表
        getFilteList(uploadData = this.uploadData) {
            this.loading = true;
            // 只要最新一条
            if (this.justShowFistOne) {
                this.uploadData.latestFlag = true;
            }
            // 排序方式
            if (this.sort) {
                this.uploadData.sort = "ASC";
            }
            commonAPI
                .getFileList(uploadData)
                .then((res) => {
                    this.loading = false;
                    // 此处刷新列表引发死循环
                    res.content.length > 0
                        ? this.$emit("uploadSuccess", true, res.content)
                        : this.$emit("uploadSuccess", "");
                    this.fileList = res.content;
                    this.$emit(
                        "changeFile",
                        this.uploadData.resourceType,
                        this.fileList.length > 0 ? false : true
                    );
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        //上传之前
        beforeUpload() {
            this.loading = true;
        },
        //上传成功
        uploadSuccess(data) {
            if (data.code === 200) {
                this.$message({
                    message: "文件上传成功",
                    type: "success",
                });
                // this.$emit("uploadSuccess", true);
                this.getFilteList();
            } else {
                this.loading = false;
                this.$message({
                    message: data.message || "上传失败",
                    type: "error",
                });
            }
        },

        //上传失败
        uploadError() {
            this.loading = false;
        },

        //删除附件
        deleteFile(data) {
            this.deleteFileById(data.id);
        },

        //删除附件
        deleteFileById(id) {
            this.loading = true;
            commonAPI
                .deleteFileById({ id: id })
                .then(() => {
                    this.$message({
                        message: "删除文件成功",
                        type: "success",
                    });
                    this.getFilteList();
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        //下载附件
        downLoadFile(data) {
            let xhr = new XMLHttpRequest(),
                that = this;
            xhr.open(
                "get",
                `${window.configPath.baseUrl}/filem/download?id=${data.id}`,
                true
            );
            xhr.setRequestHeader(
                "access-token",
                this.commonJs.getCookie("ERPToken")
            ); // 添加token到请求头
            this.loading = true;
            xhr.responseType = "blob"; // 返回类型blob  blob 存储着大量的二进制数据
            xhr.onload = function () {
                that.loading = false;
                if (this.status === 200) {
                    var blob = this.response;
                    var reader = new FileReader();
                    reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
                    reader.onload = function (e) {
                        var a = document.createElement("a"); // 转换完成，创建一个a标签用于下载
                        a.download = data.name;
                        a.href = e.target.result;
                        document.body.appendChild(a); // 火狐浏览器 必须把元素插入body中
                        a.click();
                        document.body.removeChild(a);
                    };
                }
            };
            xhr.send(); // 发送ajax请求
        },

        //查看附件
        seeView(data) {
            if (
                !this.commonJs.filterIsImg(data.name) &&
                !this.commonJs.filterIsPdf(data.name)
            ) {
                return this.$message({
                    message: "当前只支持预览pdf和图片",
                    type: "warning",
                });
            }
            this.filename = data.name;
            /* 为图片时  大图查看 */
            if (this.commonJs.filterIsImg(data.name)) {
                this.showViewer = true;
                this.guidePic = `${window.configPath.baseUrl}/filem/download?id=${data.id}&access-token=${this.token}`;
            } else if (this.commonJs.filterIsPdf(data.name)) {
                /* 为pdf时  大图查看 */
                this.showPdfView = true;
                this.pdfUrl = `${window.configPath.baseUrl}/filem/view?id=${data.id}`;
            }
        },

        //大图查看关闭
        showViewerClose() {
            this.showViewer = false;
        },

        /* 关闭弹框 */
        closeDialog() {
            this.showPdfView = false;
        },

        /*  ****************************业务模块部分的特殊处理*******************************  */
        //打开上传合同弹窗
        uploadContractPop() {
            this.$emit("openUploadContract", this.uploadData.resourceType);
        },

        //合同附件变更记录弹框
        contractRecordPop() {
            this.$emit("openContractRecord", true);
        },
    },

    computed: {
        configHeader() {
            return { "access-token": this.commonJs.getCookie("ERPToken") };
        },
    },
};
</script>

<style lang="scss" scoped>
.fileName span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}
</style>
