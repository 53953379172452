// 下载模板
// popDownLoadTemFile
<template>

    <div class="popDownLoadTemFile">
        <el-dialog :close-on-click-modal="false"
                   title="下载模板"
                   :visible.sync="dialogVisible"
                   width="30%"
                   :before-close="close">
            <el-card shadow="always"
                     class="mb20">
                <div class="recommendFile fileBox">
                    <div class="title">推荐文件</div>
                    <div class="warp">
                        <el-row class="fileItem"
                                v-if="recommendFile && recommendFile.id">
                            <el-col :span="10">{{recommendFile.name}}</el-col>
                            <el-col :span="12">{{recommendFile.updateTime}}</el-col>
                            <el-col :span="2">
                                <el-link type="primary"
                                         @click="downLoad(recommendFile)">下载</el-link>
                            </el-col>
                        </el-row>
                        <div class="emptyBg"
                             v-else></div>
                    </div>
                </div>
            </el-card>
            <el-card shadow="always">
                <div class="otherFiles fileBox">
                    <div class="title">可选模板</div>
                    <div class="warp"
                         v-if="otherFiles.length">
                        <el-row v-for="(item,idx) in otherFiles"
                                class="fileItem"
                                :key="idx">
                            <el-col :span="10">{{item.name}}</el-col>
                            <el-col :span="12">{{item.updateTime}}</el-col>
                            <el-col :span="2">
                                <el-link type="primary"
                                         @click="downLoad(item)">下载</el-link>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="emptyBg"
                         v-else></div>
                </div>
            </el-card>

        </el-dialog>

    </div>
</template>

<script>
import { Loading } from "element-ui";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "popDownLoadTemFile",

    props: {
        // 模块名称 因为项目和实施比较特殊
        modelName: {
            type: String,
            default: null,
        },
        resourceId: {
            // 项目Id
            type: [String, Number],
            default: "0",
        },
        dataRecommendTep: {
            // 待下载的文件模板参数
            type: Object,
            default() {
                return {};
            },
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            chooseFileArr: [], // 选中的文件
            recommendFile: null, // 推荐文件
            otherFiles: [], // 其他文件
        };
    },

    created() {},

    mounted() {
        // 获取推荐文件列表
        this.getRecommendFileList();
        // 获取文件列表
        this.getFileList();
    },

    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 下载文件
        downLoad(data) {
            this.commonJs.fileDownLoad(data);
        },
        // 获取推荐文件列表
        getRecommendFileList() {
            let loading = Loading.service({
                target: document.querySelector(".recommendFile"),
            });
            if (this.modelName === "项目管理") {
                commonAPI
                    .getFileListForPro({
                        resourceType: this.dataRecommendTep.resourceType,
                        resourceId: this.resourceId,
                        // resourceType: "RESERVE_PRO_PRE_PLAN", // 有数据
                    })
                    .then((res) => {
                        loading.close();
                        if (res.content) {
                            this.recommendFile = res.content;
                            this.$set(this.recommendFile, "isChoose", false);
                        }
                    })
                    .catch(() => {
                        loading.close();
                    });
            } else if (this.modelName === "实施管理") {
                commonAPI
                    .getFileListForIm({
                        resourceType: this.dataRecommendTep.resourceType,
                        resourceId: this.resourceId,
                        // resourceType: "RESERVE_PRO_PRE_PLAN", // 有数据
                    })
                    .then((res) => {
                        loading.close();
                        if (res.content) {
                            this.recommendFile = res.content;
                            this.$set(this.recommendFile, "isChoose", false);
                        }
                    })
                    .catch(() => {
                        loading.close();
                    });
            } else {
                commonAPI
                    .getRecommendFileList({
                        resourceType: this.dataRecommendTep.resourceType,
                        resourceId: this.resourceId,
                        // resourceType: "RESERVE_PRO_PRE_PLAN", // 有数据
                    })
                    .then((res) => {
                        loading.close();
                        if (res.content) {
                            this.recommendFile = res.content;
                            this.$set(this.recommendFile, "isChoose", false);
                        }
                    })
                    .catch(() => {
                        loading.close();
                    });
            }
        },
        // 获取文件列表
        getFileList() {
            let loading = Loading.service({
                target: document.querySelector(".otherFiles"),
            });
            commonAPI
                .getFileList({
                    isTemplate: true,
                    // latestFlag: true,
                    // resourceId: this.resourceId,
                    resourceType: this.dataRecommendTep.resourceType,
                    // resourceId: "1", // 有数据
                    // resourceType: "RESERVE_PRO_PRE_PLAN", // 有数据
                })
                .then((res) => {
                    loading.close();
                    if (res.content) {
                        this.otherFiles = res.content;
                    }
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.fileBox {
    margin-bottom: 15px;
}
.fileBox .title {
    margin-bottom: 10px;
}
.fileBox .fileItem {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
}
.fileBox .warp {
    max-height: 300px;
    overflow: auto;
}
.fileBox ::v-deep .el-checkbox__inner {
    border-radius: 50%;
    border: 1px solid #999;
}
</style>